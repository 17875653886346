<template>
  <div class="min-w1200 bg-f">
    <!-- 首页轮播 模块1 -->
    <v-swiper
      v-if="$t.homeSwiper ? $t.homeSwiper : false"
      :loop="true"
      :list="$t.homeSwiper || []"
      :navigation-next-el="
        function next() {
          return $refs.next;
        }
      "
      :navigation-prev-el="
        function prev() {
          return $refs.prev;
        }
      "
      :show-pagination="false"
      wrapper-class="h-527"
    >
      <template slot="navigation">
        <div class="txt-r poa main-model1-btn">
          <span ref="prev" class="dis-ib cp swiper-prev"></span>
          <span ref="next" class="dis-ib cp swiper-next ml-15"></span>
        </div>
      </template>
      <template v-slot="slotProps">
        <div class="swiper-slide">
          <img class="w-p100 h-p100 object-c" :src="slotProps.item.image1" />
          <h3
            class="
              poa
              cp
              fs-48
              c-f
              h-p100
              dis-flex
              a-i-center
              j-c-center
              main-swiper-title
            "
            v-html="slotProps.item.title"
            @click="toLink(slotProps.item.url)"
          ></h3>
        </div>
      </template>
    </v-swiper>
    <!-- 模块2 - 关于嘤鸣 -->
    <div class="dis-flex a-i-center main-model2" :style="mainModel2">
      <div class="w-1040 mlr-auto c-f">
        <h3 class="fs-18 fw-b">{{ $g("homeSlogan.title") }}</h3>
        <i class="dis-ib h-6 w-35 bg-main mt-15"></i>
        <p
          class="mt-30 lh-20 w-550 ck-content"
          v-html="$g('homeSlogan.bigtext')"
        ></p>
        <div>
          <span
            class="mt-30 dis-ib bg-main pl-15 pr-10 c-f por cp main-more-btn"
            @click="toAboutus"
            >{{ $g("homeSlogan.ext1")
            }}<img
              width="23"
              class="ml-10"
              height="11"
              src="/static/images/main/icon-more.png"
              alt=""
          /></span>
        </div>
        <div class="w-100 txt-c mt-40">
          <img width="60" :src="$g('homeSlogan.image1')" alt="" />
          <p class="mt-10 fs-12 c-e0e2e5">{{ $g("homeSlogan.ext2") }}</p>
        </div>
      </div>
    </div>
    <!-- 模块3 - 公司业务 -->
    <div class="ptb-95">
      <div class="w-1040 mlr-auto">
        <h3 class="txt-c fs-18 fw-b">{{ $g("homeBusinessTitle.title") }}</h3>
        <p class="txt-c mb-45"><i class="dis-ib h-6 w-35 bg-main mt-15"></i></p>
        <ul class="mlr-140 main-model3-list">
          <li
            v-for="(item, index) in $t.homeBusinessContent || []"
            :key="index"
            class="
              dis-flex
              cp
              a-i-center
              h-100
              ov-h
              plr-40
              bra-10
              bg-edf1f5
              mt-10
            "
            @mouseenter="model3Mouseenter(item)"
            @mouseleave="model3Mouseleave(item)"
            @click="toBusiness(index)"
          >
            <img
              v-if="item.ext1 == 'false'"
              width="36"
              :src="item.image1"
              alt=""
            />
            <img v-else width="36" :src="item.image2" alt="" />
            <ul class="flex-1 plr-30">
              <li class="fs-16 c-313848 fw-b">{{ item.title }}</li>
              <li class="fs-12 c-9 mt-10">{{ item.content }}</li>
            </ul>
            <span class="c-e0e2e5 fs-36">0{{ index + 1 }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 模块4 - 医讯药闻 -->
    <div
      class="main-model4"
      v-if="$g('homeNewTitle.ext2') != '1'"
      :style="mainModel4"
    >
      <div class="w-1040 mlr-auto j-c-center">
        <div class="dis-flex j-c-between a-i-center pt-100">
          <h3>
            <p class="fs-18 c-f fw-b">{{ $g("homeNewTitle.title") }}</p>
            <p><i class="dis-ib h-6 w-35 bg-main mt-15"></i></p>
          </h3>
          <span
            class="dis-ib bg-main pl-15 pr-10 c-f por cp main-more-btn"
            @click="toNew"
            >{{ $g("homeSlogan.ext1")
            }}<img
              width="23"
              class="ml-10"
              height="11"
              src="/static/images/main/icon-more.png"
              alt=""
          /></span>
        </div>
        <v-swiper
          v-if="getSwiper() ? getSwiper().length : false"
          :list="getSwiper()"
          :loop="true"
          :show-pagination="true"
          :slides-perview="3"
          :slides-pergroup="3"
          :space-between="43"
          :show-navigation="false"
          wrapper-class="mt-50 h-400"
        >
          <template v-slot="slotProps">
            <div class="swiper-slide">
              <div
                class="txt-l bg-f pb-20 br-t-30 cp main-model4-list"
                @click="toNewDetail(slotProps.item)"
              >
                <img
                  class="w-p100 br-t-30 object-c"
                  height="165"
                  :src="slotProps.item.image1"
                />
                <div class="plr-20 mt-20">
                  <p class="c-6 fs-12">{{ slotProps.item.ext1 }}</p>
                  <h3
                    class="
                      mt-15
                      c-3
                      h-40
                      dis-flex
                      a-i-center
                      j-c-center
                      text-td-2
                      main-swiper-title
                    "
                  >
                    {{ slotProps.item.title }}
                  </h3>
                  <div class="fs-12 mt-30">
                    <span class="cp c-6">{{ slotProps.item.ext2 }}</span>
                    <p class="lh-0 mt-5"><i class="dis-b w-20 h-2 bg-6"></i></p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </v-swiper>
      </div>
    </div>
    <!-- 模块5 - 合作伙伴 -->
    <div class="pt-40 h-500 main-model5">
      <div class="w-1040 mlr-auto dis-flex a-i-center j-c-between">
        <div class="mr-120">
          <h3 class="fs-18 fw-b">{{ $g("homePartnerTitle.title") }}</h3>
          <div><i class="dis-ib h-6 w-35 bg-main mt-10"></i></div>
          <span
            class="mt-35 dis-ib bg-main pl-15 pr-10 c-f por cp main-more-btn"
            @click="toAboutus('friends')"
            >{{ $g("homePartnerTitle.ext1")
            }}<img
              width="23"
              class="ml-10"
              height="11"
              src="/static/images/main/icon-more.png"
              alt=""
          /></span>
        </div>
        <v-swiper
          v-if="$t.homePartnerContent ? $t.homePartnerContent.length : false"
          :list="$t.homePartnerContent || []"
          :navigation-next-el="
            function next1() {
              return $refs.next1;
            }
          "
          :navigation-prev-el="
            function prev1() {
              return $refs.prev1;
            }
          "
          :slides-perview="4"
          :slides-pergroup="4"
          :slides-percolumn="3"
          :show-navigation="true"
          :show-pagination="false"
          :space-between="30"
          :autoplay="false"
          contain-class="h-360 flex-1 plr-20"
        >
          <template slot="navigation">
            <div>
              <img
                ref="prev1"
                class="dis-ib poa cp main-model5-prev"
                width="31"
                src="/static/images/main/icon-prev-g.png"
              />
              <img
                ref="next1"
                class="dis-ib poa cp main-model5-next"
                width="31"
                src="/static/images/main/icon-next-g.png"
              />
            </div>
          </template>
          <template v-slot="slotProps">
            <div class="h-100 swiper-slide">
              <div class="w-p100">
                <img class="cp main-model5-img" :src="slotProps.item.image1" />
              </div>
            </div>
          </template>
        </v-swiper>
      </div>
    </div>
    <!-- 模块6 - 联系我们 -->
    <div class="dis-flex a-i-center h-190 c-f main-model6" :style="mainModel6">
      <div class="w-1040 mlr-auto">
        <h3 class="fs-18 fw-b">{{ $g("homeContact.title") }}</h3>
        <i class="dis-ib h-6 w-35 bg-main mt-10"></i>
        <p class="mt-15" v-html="$g('homeContact.content')"></p>
      </div>
    </div>
    <!-- 模块7 - 地址 -->
    <div class="dis-flex a-i-center c-3 h-220 main-model7">
      <ul class="w-1040 mlr-auto dis-flex a-i-center j-c-between">
        <li
          v-for="(it, index) in $t.homeAddress || []"
          :key="index"
          class="w-400"
        >
          <h3
            :class="[
              'pb-10 fs-16 por main-model7-city ',
              index == 0 ? 'sh' : 'bj',
            ]"
          >
            {{ it.ext3 }}
          </h3>
          <div class="mt-10 dis-flex a-i-center j-c-between">
            <ul class="flex-1">
              <li class="h-40 dis-flex">
                <span class="w-20 txt-c mr-15"
                  ><img
                    class="va-m"
                    :src="$g('homeAddressIcon.image1')"
                    width="14"
                    height="16"
                    alt=""
                /></span>
                <p class="w-70">{{ it.title }}</p>
                <p class="flex-1">{{ it.ext4 }}</p>
              </li>
              <li class="h-40 dis-flex a-i-center mt-30">
                <span class="w-20 txt-c mr-15"
                  ><img
                    class="va-m"
                    :src="$g('homeAddressIcon.image2')"
                    width="18"
                    height="13"
                    alt=""
                /></span>
                <p class="w-70">{{ it.content }}</p>
                <p class="flex-1">{{ it.ext5 }}</p>
              </li>
            </ul>
            <ul class="w-100 fs-12">
              <li><img :src="it.image1" width="98" height="98" alt="" /></li>
              <li>{{ it.image1Tip }}</li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Swiper from "@/components/swiper.vue";
export default {
  name: "Home",
  components: {
    "v-swiper": Swiper,
  },
  data() {
    return {};
  },
  computed: {
    mainModel2() {
      let me = this;
      return `
       background-image: url(${me.$g("homeSlogan.image2")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
    mainModel4() {
      let me = this;
      return `
       background-image: url(${me.$g("homeNewTitle.image1")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
    mainModel6() {
      let me = this;
      return `
       background-image: url(${me.$g("homeContact.image1")});
       background-repeat: no-repeat;
       background-attachment:center;
       background-size:cover;`;
    },
  },
  methods: {
    getSwiper() {
      let me = this;
      let aArr =  me.$a.newArticle || [];
      if (aArr.length > 15) {
        return aArr.slice(0, 15);
      }
      return aArr;
    },
    model3Mouseenter(item) {
      item.ext1 = "true";
    },
    model3Mouseleave(item) {
      item.ext1 = "false";
    },
    toLink(url) {
      location.href = url;
    },
    toAboutus(id) {
      if (id == "friends") {
        this.$router.push({
          path: "/aboutus",
          query: { id: id },
        });
      } else {
        this.$router.push("/aboutus");
      }
    },
    toBusiness(index) {
      this.$router.push({
        path: "/business/detail",
        query: { index: index },
      });
    },
    toNewDetail(item) {
      this.$router.push({ name: "NewDetail", query: { id: item.id } });
    },
    toNew() {
      this.$router.push("/new");
    },
  },
};
</script>
<style>
.main-model1-btn {
  bottom: 100px;
  right: 145px;
  left: 0;
  z-index: 1;
}
.main-model1-btn .swiper-prev {
  height: 34px;
  width: 34px;
  background: url(/static/images/main/icon-prev.png) no-repeat center;
}
.main-model1-btn .swiper-prev:hover {
  transform: rotate(180deg);
  background: url(/static/images/main/icon-next-red.png) no-repeat center;
}
.main-model1-btn .swiper-next {
  height: 34px;
  width: 34px;
  transform: rotate(180deg);
  background: url(/static/images/main/icon-prev.png) no-repeat center;
}

.main-model1-btn .swiper-next:hover {
  transform: rotate(0deg);
  background: url(/static/images/main/icon-next-red.png) no-repeat center;
}
.main-model2 {
  height: 570px;
  /* background: url(/static/images/main/model2-bg.jpg) no-repeat center;
  background-size: cover; */
}
.main-more-btn {
  height: 38px;
  line-height: 38px;
}
.main-model3-list li:hover {
  background: #b41122;
}
.main-model3-list li:hover li {
  color: #fff;
}
.main-model4 {
  height: 630px;
  /* background: url(/static/images/main/model4-bg.jpg) no-repeat center;
  background-size: cover; */
}
.main-model6 {
  /* background: url(/static/images/main/model6-bg.jpg) no-repeat center;
  background-size: cover; */
}
.main-model7-city {
  border-bottom: 2px solid #252525;
  z-index: 1;
}
.bj::before {
  content: "BEIJINFG";
  position: absolute;
  font-size: 34px;
  color: #fff5f5;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.sh::before {
  content: "SHANGHAI";
  position: absolute;
  font-size: 34px;
  color: #fff5f5;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.main-model4-list:hover h3,
.main-model4-list:hover p,
.main-model4-list:hover span {
  color: #b02125 !important;
}
.main-model4-list:hover i {
  background: #b02125;
}
.main-model5-prev {
  left: 0px;
  top: 50%;
  margin-top: -15px;
  z-index: 1;
}
.main-model5-next {
  right: 0px;
  top: 50%;
  margin-top: -15px;
  z-index: 1;
}
.main-model5-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.main-swiper-title {
  left: 0;
  right: 0;
  top: 0;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border: 50%;
  background: rgba(255, 255, 255, 0.23);
}
.swiper-pagination-bullet-active {
  background: #fff;
}
</style>
